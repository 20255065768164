import React, { Fragment } from 'react'
import moment from 'moment'
import { Container, IconButton, Toolbar, Typography } from '@mui/material'
import clsx from 'clsx'
import { getCurrentLanguage, localize } from '../../services/index'
import { Facebook, LinkedIn } from '@mui/icons-material'
import { BottomBar, BottomBarDivider, MenuButton } from './styles'

const PublicFooter = props => {
  const { externalLinks } = props
  const links = externalLinks ? externalLinks[getCurrentLanguage()] : {}
  
  return (
    <Fragment>
      <div className="bottomBar">
        <BottomBar position="static">
          <Toolbar className="topBar" sx={{ justifyContent: 'space-between' }}>
            <Container className={clsx('d-flex flex-column')}>
              <div className={clsx(
                'd-flex flex-wrap w-full justify-content-center dark-text m-3 font-size-16')}>
                <MenuButton
                  href={links?.contact || localize('externalLinks.contact')}
                  target="_blank"
                >
                  {localize('contact')}
                </MenuButton>
                <MenuButton
                  href={links?.faq || localize('externalLinks.faq')}
                  target="_blank"
                >
                  {localize('faq')}
                </MenuButton>
                <MenuButton
                  href={links?.mediaKit || localize('externalLinks.mediaKit')}
                  target="_blank"
                >
                  {localize('mediaKit')}
                </MenuButton>
                <MenuButton
                  href={links?.legal || localize('externalLinks.legal')}
                  target="_blank"
                >
                  {localize('legal')}
                </MenuButton>
                <MenuButton
                  href={links?.support || localize('externalLinks.support')}
                  target="_blank"
                >
                  {localize('support')}
                </MenuButton>
              </div>
              <div className={clsx(
                'd-flex w-full justify-content-center no-wrap')}>
                <IconButton
                  href={links?.facebook || localize('externalLinks.facebook')}
                  target="_blank"
                  color="primary"
                >
                  <Facebook fontSize="large"/>
                </IconButton>
                <IconButton
                  href={links?.linkedin || localize('externalLinks.linkedin')}
                  target="_blank"
                  color="primary"
                >
                  <LinkedIn fontSize="large"/>
                </IconButton>
              </div>
              <BottomBarDivider className="my-4"/>
              <div
                className={clsx(
                  'd-flex w-full justify-content-center text-center py-4 mb-4 light-text'
                )}
              >
                <Typography>
                  {localize('copyright', moment().year())}
                </Typography>
              </div>
            </Container>
          </Toolbar>
        </BottomBar>
      </div>
    </Fragment>
  )
}

export default PublicFooter

import React from 'react'

export function ErrorIconSvg () {
  return <svg width="246" height="220" viewBox="0 0 246 220" fill="none"
              xmlns="http://www.w3.org/2000/svg">
    <path opacity="0.05"
          d="M3.14579 52.228C-0.185842 61.6126 -0.887106 71.7331 1.11823 81.4892C3.94066 94.4979 13.6607 105.938 12.004 119.808C11.0091 128.138 11.1868 135.765 10.986 143.948C10.5751 160.744 22.2842 174.65 36.0201 181.61C47.1774 187.262 59.8614 188.754 72.1084 187.673C83.1288 186.701 93.9815 183.8 104.457 180.206C114.553 176.742 124.352 172.437 135.236 172.76C144.289 173.027 152.683 176.4 161.275 178.742C181.786 184.334 206.053 183.539 223.487 171.422C242.597 158.139 250.316 132.936 243.638 110.91C240.056 99.0961 235.274 88.857 237.004 76.2694C237.855 70.077 239.124 63.7751 237.952 57.6368C236.874 51.9866 233.783 46.9025 230.118 42.4763C220.235 30.5412 206.166 22.9305 191.541 18.4534C174.949 13.3739 156.53 10.6044 139.277 13.2343C105.763 18.3431 98.4698 13.3747 63.4364 8.38934C39.9675 5.04983 17.9382 23.7527 7.24325 43.0478C5.61594 45.9837 4.24502 49.0552 3.14579 52.228Z"
          fill="#2F80ED"/>
    <path opacity="0.05"
          d="M30.9987 81.5251C35.84 75.1189 43.3247 70.7669 46.9914 63.4057C51.3813 54.5925 50.4567 44.1919 54.3773 35.1876C60.171 21.8838 70.6618 10.5831 83.8036 4.37742C90.136 1.38681 97.0858 -0.432793 103.87 0.0888621C114.321 0.892242 123.336 7.13198 130.386 14.5837C140.878 25.6732 148.296 41.4234 160.888 50.3086C167.834 55.2125 176.003 50.0098 183.365 48.3597C191.616 46.5646 200.182 46.8542 208.293 49.2026C222.942 53.382 235.556 63.908 241.865 77.7822C248.601 92.5959 247.643 113.075 235.001 125.813C229.153 131.705 221.221 135.643 216.881 142.701C211.434 151.558 210.795 163.53 209.274 173.378C207.519 184.444 202.944 194.867 195.993 203.64L195.755 203.939C189.911 211.246 181.844 216.434 172.784 218.712C163.724 220.99 154.17 220.232 145.58 216.555L108.527 200.456C70.9303 184.121 42.3885 157.797 28.6857 119.654C25.7205 111.4 24.6431 93.8033 28.421 85.7432C29.1307 84.2506 29.9947 82.8369 30.9987 81.5251Z"
          fill="#2F80ED"/>
    <path
      d="M177.758 69.0664H157.75V78.5228H176.586V135.275H82.4143V78.5228H101.25V69.0664H81.2419C76.6937 69.0664 73 72.7766 73 77.3381V136.453C73 141.014 76.6937 144.732 81.2419 144.732H120.086V154.188H110.671C108.072 154.188 105.961 156.309 105.961 158.92C105.961 161.53 108.065 163.652 110.664 163.652H148.329C150.928 163.652 153.039 161.53 153.039 158.92C153.039 156.309 150.935 154.188 148.329 154.188H138.914V144.732H177.758C182.299 144.732 186 141.022 186 136.453V77.3381C186 72.7766 182.306 69.0664 177.758 69.0664Z"
      fill="#2F80ED"/>
    <path fillRule="evenodd" clipRule="evenodd"
          d="M105.644 80.4691C105.644 67.7211 116.018 57.3477 128.766 57.3477C141.514 57.3477 151.887 67.7264 151.887 80.4744C151.887 93.2224 141.519 103.596 128.766 103.596C116.012 103.596 105.644 93.2171 105.644 80.4691ZM131.69 79.293C131.3 79.6835 131.3 80.3166 131.69 80.7072L137.714 86.7306C137.899 86.9161 138.001 87.1633 138.001 87.4268C138.001 87.6904 137.899 87.9378 137.714 88.123L137.124 88.7127C136.938 88.8983 136.691 89.0001 136.428 89.0001H136.427C136.164 89.0001 135.917 88.8983 135.731 88.7127L129.708 82.6893C129.317 82.2988 128.684 82.2988 128.294 82.6893L122.27 88.7127C122.085 88.8983 121.838 89.0001 121.574 89.0001C121.311 89.0001 121.063 88.8983 120.878 88.7127L120.288 88.123C120.103 87.9376 120.001 87.6904 120.001 87.4268C120.001 87.1633 120.103 86.9161 120.288 86.7305L126.311 80.7072C126.702 80.3166 126.702 79.6835 126.312 79.2929L120.288 73.2693C119.904 72.8857 119.904 72.2611 120.288 71.8771L120.878 71.2874C121.063 71.1017 121.311 71 121.574 71C121.838 71 122.085 71.1017 122.27 71.2874L128.294 77.3107C128.684 77.7012 129.318 77.7012 129.708 77.3107L135.731 71.2874C135.917 71.1017 136.164 71 136.427 71C136.691 71 136.938 71.1017 137.124 71.2874L137.714 71.8771C137.899 72.0621 138.001 72.3096 138.001 72.5733C138.001 72.8369 137.899 73.0841 137.714 73.2693L131.69 79.293Z"
          fill="#EF8274"/>
  </svg>
}

import i18n from './I18n/I18n'
import { languageCodes } from '../../constants/Languages'

export function localize (key, ...variable) {
  return i18n.t(key, { postProcess: 'sprintf', sprintf: variable })
}

export function changeLanguage (newLanguage) {
  if (Object.values(languageCodes).includes(newLanguage)) {
    localStorage.setItem('currentLanguageCode', newLanguage)
    i18n.changeLanguage(newLanguage, (err) => {
      if (err) throw new Error('There is an error with language change' + err)
    })
  }
}

export function checkAndForceChangeLanguage (newLanguage) {
  if (Object.values(languageCodes).includes(newLanguage)) {
    let willReload = false
    if (newLanguage !== localStorage.getItem('currentLanguageCode')) {
      willReload = true
      localStorage.setItem('currentLanguageCode', newLanguage)
      i18n.changeLanguage(newLanguage, () => {
        location.reload()
      })
    }
    return willReload
  }
}

export function initializeLanguage () {
  const urlParams = new URLSearchParams(window.location.search)
  const urlLanguage = urlParams.get('language')
  const browserLanguage = getBrowserLocales()
  const currentLanguage = localStorage.getItem('currentLanguageCode')
  if (!currentLanguage) changeLanguage(urlLanguage || browserLanguage[0])
}

export function getCurrentLanguage () {
  return i18n.language  ?? languageCodes.SL
}

function getBrowserLocales (options = {}) {
  const defaultOptions = {
    languageCodeOnly: true
  }
  
  const opt = {
    ...defaultOptions,
    ...options
  }
  
  const browserLocales =
    navigator.languages === undefined
      ? [navigator.language]
      : navigator.languages
  
  if (!browserLocales) {
    return undefined
  }
  
  return browserLocales.map(locale => {
    const trimmedLocale = locale.trim()
    
    return opt.languageCodeOnly
      ? trimmedLocale.split(/-|_/)[0]
      : trimmedLocale
  })
}

import GlobalManager from '../services/GlobalManager'

const decimal = ','
const group = '.'

class NumberFormat {
  static formatQuantity (quantity) {
    const quantityTmp = GlobalManager.toFixedNoRounding(quantity)
    return this.format(quantityTmp) + ' g'
  }
  
  static formatAmount (amount) {
    return this.formatToTwoDecimals(amount) + this.currency()
  }
  
  static formatPercentage (percentage) {
    return this.formatToTwoDecimals(percentage) + ' %'
  }
  
  static currency () {
    return ' €'
  }
  
  static formatToTwoDecimals (value, integer = null) {
    if (!GlobalManager.isEmpty(value)) {
      const tmpValue = GlobalManager.toFixedNoRounding(Number(value), 2)
      const strValue = tmpValue.toString()
      let intValue
      let decimalValue
      
      if (strValue.indexOf('.') !== -1) {
        intValue = strValue.split('.')[0]
        decimalValue = strValue.split('.')[1]
      } else {
        intValue = strValue
        decimalValue = '00'
      }
      const intValueGroup = intValue.toString().
        replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1' + group)
      if (integer) {
        return intValueGroup
      } else {
        return intValueGroup + decimal + decimalValue
      }
    }
    return value
  }
  
  static format (value, integer = null) {
    if (!GlobalManager.isEmpty(value)) {
      const tmpValue = GlobalManager.toFixedNoRounding(Number(value))
      const strValue = tmpValue.toString()
      let intValue
      let decimalValue
      
      if (strValue.indexOf('.') !== -1) {
        intValue = strValue.split('.')[0]
        decimalValue = strValue.split('.')[1]
      } else {
        intValue = strValue
        decimalValue = '0000'
      }
      const intValueGroup = intValue.toString().
        replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1' + group)
      if (integer) {
        return intValueGroup
      } else {
        return intValueGroup + decimal + decimalValue
      }
    }
    return value
  }
}

export default NumberFormat

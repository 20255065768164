import React, { Fragment } from 'react'

import PagesError404Content
  from '../../components/PagesError404/PagesError404Content/index'

export default function PagesError404 () {
  return (
    <Fragment>
      <PagesError404Content/>
    </Fragment>
  )
}

import {
  HIDE_ERROR_NOTIFICATION,
  SHOW_ERROR_NOTIFICATION
} from '../actions/types'

const initialState = {
  show: false,
  title: '',
  message: ''
}

export default function (state = initialState, action) {
  if (action.type === SHOW_ERROR_NOTIFICATION) {
    return {
      ...state,
      show: true,
      title: action.payload.title,
      message: action.payload.message
    }
  }
  if (action.type === HIDE_ERROR_NOTIFICATION) {
    return {
      ...state,
      show: false,
      title: '',
      message: ''
    }
  }
  return state
}

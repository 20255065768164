import React, { Fragment } from 'react'
import {
  accountStatus,
  moneyTransferTypes,
  productIds,
  showMoneyColor,
  signalTypes
} from 'constants/Constants'
import { localize } from 'services'
import GlobalManager from './GlobalManager'
import NumberFormat from '../utils/NumberFormat'

class UIManager {
  static ifExist (value, type = signalTypes.MISSING, prefix = '') {
    return GlobalManager.isEmpty(value)
      ? this.showNoExistMessage(type, prefix)
      : value
  }
  
  static showNoExistMessage (type = signalTypes.MISSING, prefix = '') {
    if (type === signalTypes.INCOMPLETE) {
      return <Fragment>{prefix} <span
        className="badge badge-info">{localize('signalTypes.incomplete')}</span></Fragment>
    }
    if (type === signalTypes.MISSING) {
      return <Fragment>{prefix} <span
        className="badge badge-warning">{localize('signalTypes.missing')}</span></Fragment>
    }
    if (type === signalTypes.REQUIRED) {
      return <Fragment>{prefix} <span
        className="badge badge-danger">{localize('signalTypes.required')}</span></Fragment>
    }
    if (type === signalTypes.NO_INFORMATION) {
      return <Fragment>{prefix} <span
        className="badge badge-info">{localize(
        'signalTypes.noInformation')}</span></Fragment>
    }
  }
  
  static ifIsNotNullAndIfExist (
    data, property, type = signalTypes.MISSING, prefix = '') {
    if (!GlobalManager.isEmpty(data) &&
      !GlobalManager.isEmpty(data[property])) {
      return data[property]
    } else {
      return this.ifExist(data, type, prefix)
    }
  }
  
  static getPercentOfCompleted (data, property) {
    return parseFloat(data.advanced[property].percent)
  }
  
  static getLastOrderAmountIfExist (data) {
    if (!GlobalManager.isEmpty(data) && data.length > 0) {
      return data[data.length - 1].amount
    }
  }
  
  static ifExistShow (value, showValue) {
    if (!GlobalManager.isEmpty(value)) {
      return showValue
    } else {
      return ''
    }
  }
  
  static renderCurrency () {
    return NumberFormat.currency()
  }
  
  static renderPercentageSymbol () {
    return ' %'
  }
  
  static renderPercentage (percentage) {
    return NumberFormat.formatPercentage(percentage)
  }
  
  static getAmountStyle (amount) {
    if (amount > 0) {
      return 'text-success'
    }
    if (amount < 0) {
      return 'text-danger'
    } else {
      return 'text-first'
    }
  }
  
  static renderQuantity (quantity, color = showMoneyColor.BLACK) {
    const quantityTmp = NumberFormat.formatQuantity(quantity || 0)
    return <span
      className={'font-weight-bold ' + (color !== null
        ? this.getAmountStyle(quantity)
        : '')}>{quantityTmp}</span>
  }
  
  static renderAmount (amount, color = showMoneyColor.BLACK) {
    const amountTmp = NumberFormat.formatAmount(amount)
    return <span
      className={'font-weight-bold text-' + color + ' ' +
        (color !== null ? this.getAmountStyle(amount) : '')}>{amountTmp}</span>
  }
  
  static formatNumber (number, integer = null) {
    return NumberFormat.format(number, integer)
  }
  
  static getQuantityStyle (quantity) {
    if (quantity > 0) {
      return 'text-success'
    }
    if (quantity < 0) {
      return 'text-danger'
    } else {
      return 'text-first'
    }
  }
  
  static mapProductIdToName = (id) => {
    switch (id) {
      case productIds.GOLD:
        return localize('products.gold')
      case productIds.SILVER:
        return localize('products.silver')
      case productIds.PLATINUM:
        return localize('products.platinum')
      case productIds.PALLADIUM:
        return localize('products.palladium')
      default:
        return ''
    }
  }
  
  static mapAccountStatusToString (status) {
    switch (status) {
      case accountStatus.DRAFT:
        return localize('accountStatus.draft')
      case accountStatus.PENDING_APPROVAL_KYC_NOT_APPROVED:
        return localize('accountStatus.pendingApprovalKycNotApproved')
      case accountStatus.PENDING_APPROVAL_KYC_APPROVED:
        return localize('accountStatus.pendingApprovalKycApproved')
      case accountStatus.PENDING_KYC:
        return localize('accountStatus.pendingKyc')
      case accountStatus.PENDING_PAYMENT:
        return localize('accountStatus.pendingPayment')
      case accountStatus.ON_HOLD:
        return localize('accountStatus.onHold')
      case accountStatus.ACTIVE:
        return localize('accountStatus.active')
      case accountStatus.DELETED:
        return localize('accountStatus.deleted')
      default:
        return ''
    }
  }
  
  static bgColorClassMapping = (productId) => {
    switch (productId) {
      case productIds.GOLD:
        return ' bg-gold'
      case productIds.SILVER:
        return ' bg-silver'
      case productIds.PLATINUM:
        return ' bg-platinum'
      case productIds.PALLADIUM:
        return ' bg-palladium'
      default:
    }
  }
  
  static renderMoneyTransferType (type) {
    if (type === moneyTransferTypes.SEPA) {
      return localize('paymentTypes.sepa')
    }
    if (type === moneyTransferTypes.MANUAL_PAYMENT) {
      return localize('paymentTypes.manualPayment')
    }
  }
}

export default UIManager

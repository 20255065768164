import React from 'react'
import config from '../../../config/config'
import * as icons from './assets'

export function ValoresLogo ({
  className = '',
  style = {},
  type = 'Secondary'
}) {
  const name = config.LOGO_NAME ?? 'ValoresLogo'
  const IconComponent = icons[`${name}${type}`]
  return <img alt={name} src={IconComponent} className={className}
              style={{ width: 144, height: 52, ...style }}/>
}

import React from 'react'
import { connect } from 'react-redux'
import { Button } from '@mui/material'
import { localize } from 'services'
import AuthenticationManager from 'store/actions/AuthenticationManager'
import { ValoresLogo } from '../../UI/SvgIcons'

function PagesError404Content ({ auth }) {
  return (
    <>
      <div className="app-wrapper bg-white">
        <div
          className="app-main page404-wrapper flex-column align-items-center justify-content-center">
          <div className="position-absolute ml-4" style={{ top: 100 }}>
            <ValoresLogo type="Primary"/></div>
          <div className="text-center">
            <div className="text-gold font-weight-bold page404-text__404">404
            </div>
            <div
              className="page404-text dark-text mt-4 font-weight-bold">{localize(
              'page404.text404')}</div>
            <Button
              className="page404-button mt-4 font-weight-bold"
              color="secondary"
              variant="contained"
              onClick={() => AuthenticationManager.redirectByRole(
                auth.user.role)}
              style={{ fontSize: 20 }}
            >
              {localize('form.back')}
            </Button>
          </div>
        </div>
      </div>
    </>
  )
}

const mapStateToProps = state => ({
  auth: state.Authentication
})

export default connect(mapStateToProps)(PagesError404Content)

import { styled } from '@mui/material/styles'
import {
  AppBar,
  ButtonBase,
  Divider,
  IconButton,
  Toolbar,
  Typography
} from '@mui/material'

export const TopBar = styled(AppBar)({
  backgroundColor: '#2D2D2D',
  minHeight: 60,
  fontFamily: '\'DM Sans\', serif',
  fontSize: 16,
  boxShadow: 'none'
})

export const ToolBarTitle = styled(Toolbar)({
  backgroundColor: '#ffffff',
  minHeight: 84
})

export const TopBarText = styled(Typography)({
  fontFamily: '\'DM Sans\', serif',
  padding: '5px auto',
  fontSize: 16
})

export const MenuButton = styled(ButtonBase)({
  minWidth: '60px',
  paddingRight: 16,
  paddingLeft: 16
})

export const TopBarDivider = styled(Divider)({
  height: 18,
  backgroundColor: '#fff'
})

export const IconStyled = styled(IconButton)({
  color: '#fff'
})

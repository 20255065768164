import React, { Fragment } from 'react'
import { Outlet } from 'react-router-dom'

const MinimalLayout = () => {
  return (
    <Fragment>
      <Outlet/>
    </Fragment>
  )
}

export default MinimalLayout

export const roles = {
  ADVISOR: 'Advisor',
  ADMIN: 'Admin',
  SUPER_ADMIN: 'SuperAdmin',
  CUSTOMER: 'Customer',
  TRADER: 'Trader',
  ACCOUNTANT: 'Accountant',
  RECOMMENDER: 'Recommender'
}

export const httpCodes = {
  OK: 200,
  CREATED: 201,
  BAD_REQUEST: 400,
  UNAUTHORIZED: 401,
  FORBIDDEN: 403,
  NOT_FOUND: 404,
  CONFLICT: 409,
  BAD_GATEWAY: 502,
  INVALID_ENTITY: 422,
  INTERNAL_SERVER_ERROR: 500
}
export const responseAdvancedCodes = {
  SEQUELIZE_VALIDATION_ERROR: 500,
  SEQUELIZE_FOREIGNKEY_ERROR: 501,
  SEQUELIZE_SOMETHING_IS_WRONG: 502,
  IMPORT_DATA_ERROR: 503,
  SEQUELIZE_DATA_VERSION_NOT_VALID: 504,
  INTERNAL_SOMETHING_IS_WRONG: 505,
  GK_BACKEND_EXCEPTION: 506,
  GK_BACKEND_NOT_RESPOND: 507,
  USER_BACKEND_EXCEPTION: 508,
  USER_BACKEND_NOT_RESPOND: 509,
  SMS_GATEWAY_NOT_RESPOND: 510,
  SEQUELIZE_VALIDATION_ID_MISSING: 511,
  USER_DATA_VALIDATION: 401,
  USER_TOKEN_EXPIRED: 402,
  USER_PASSWORD_EXPIRED: 403,
  USER_NOT_EXIST: 404,
  USER_API_GATEWAY_FORBIDDEN: 405,
  USER_EMAIL_NOT_VERIFIED: 406,
  USER_PHONE_NOT_EXIST: 407,
  USER_CREDENTIALS_NOT_VALIDATION: 408,
  USER_VERIFICATION_CODE_NOT_MATCH: 409,
  USER_NOT_ACTIVE: 410,
  KYC_SUCCESS_UPLOAD: 600,
  KYC_FAILURE_UPLOAD: 601,
  USER_KYC_SUCCESS_CRATED: 603,
  USER_SUCCESS_KYC_FAILURE: 604,
  ROUTE_FORBIDDEN: 700,
  ROUTE_NOT_EXIST: 701,
  USER_DOESNT_HAVE_ACCESS_DATA: 703,
  SUBSCRIPTION_NOT_CREATED: 800,
  USER_DELETED: 1604,
  REFERRAL_SETTINGS_EMPTY: 1605,
  SELL_WITHDRAWAL_EXIST: 1017,
  USER_DOESNT_HAVE_KYC: 1606,
  KYC_DOWNLOAD_ERROR: 1607
}

export const signalTypes = {
  WARNING: 'warning',
  INCOMPLETE: 'incomplete',
  REQUIRED: 'required',
  MISSING: 'missing',
  NO_INFORMATION: 'no_information'
}

export const roleMapping = (roleId) => {
  if (roleId === 0) {
    return 'Advisor'
  }
  if (roleId === 1) {
    return 'Admin'
  }
  if (roleId === 2) {
    return 'SuperAdmin'
  }
  if (roleId === 3) {
    return 'Customer'
  }
  if (roleId === 4) {
    return 'Trader'
  }
  if (roleId === 5) {
    return 'Accountant'
  }
}
export const accountTypes = {
  SAVING: 0,
  DEPO: 1
}

export const accountStatus = {
  DRAFT: 0,
  PENDING_APPROVAL_KYC_NOT_APPROVED: 1,
  PENDING_APPROVAL_KYC_APPROVED: 2,
  PENDING_KYC: 3,
  PENDING_PAYMENT: 4,
  ON_HOLD: 5,
  ACTIVE: 6,
  DELETED: 7
}

export const moneyTransferTypes = {
  SEPA: 0,
  MANUAL_PAYMENT: 1
}

export const showMoneyColor = {
  RED: 'red',
  GREEN: 'success',
  BLACK: 'black',
  MUTED: 'muted'
}

export const productIds = {
  GOLD: 1,
  SILVER: 2,
  PLATINUM: 3,
  PALLADIUM: 4
}

export const GOOGLE_ANALYTICS_USER_ID = 1
export const rolesIds = {
  ADVISOR: 0,
  ADMIN: 1,
  SUPER_ADMIN: 2,
  CUSTOMER: 3,
  TRADER: 4,
  ACCOUNTANT: 5,
  RECOMMENDER: 6
}

export const purchaseType = {
  CUSTOMER_TRANSFER: 0,
  TRANSFER_EXTERNAL_PURCHASE: 1,
  STORAGE_FEE: 2,
  CUSTOMER_WITHDRAW: 3,
  CUSTOMER_SELL: 4,
  TRANSFER_EXTERNAL_SALES: 5,
  INITIAL_STATE: 6,
  ACCOUNT_ASSET_TRANSFER: 7,
  STORAGE_FEE_INTERNAL_TRANSFER: 8,
  MAIN_ACCOUNTS_ASSET_TRANSFER: 9,
  REVERSE_CUSTOMER_TRANSFER: 10,
  STOCK_ADJUSTMENT: 11,
  REVERSE_EXTERNAL_TRANSFER: 12,
  REVERSE_SELL_TRANSFER: 13,
  REVERSE_WITHDRAW_TRANSFER: 14,
  REFERRAL_AWARD: 15
}

export const tokenType = {
  RESET_PASSWORD: 0,
  SAVING_PLAN_DATA_CONFIRMATION_TOKEN: 2,
  WITHDRAW_CUSTOMER_CONFIRMATION: 3,
  SELLING_CUSTOMER_CONFIRMATION: 4,
  REVISION_CONFIRMATION: 5,
  SETUP_PASSWORD: 6,
  TWO_FACTOR_AUTHENTICATION_CODE: 7,
  TWO_FACTOR_AUTHENTICATION_ACCESS: 8,
  SAVING_PLAN_EDIT_DATA_CONFIRMATION_TOKEN: 9,
  TRANSFER_ASSETS_CUSTOMER_CONFIRMATION: 10,
  ONE_TIME_ORDER_DATA_CONFIRMATION_TOKEN: 11,
  CLOSE_SAVING_PLAN_DATA_CONFIRMATION_TOKEN: 12,
  DEACTIVATION_CUSTOMER_CONFIRMATION_TOKEN: 13
}

export const twoFactoryVerification = {
  ENABLED: 1,
  DISABLED: 0
}
export const transactionType = {
  INBOUND: 0,
  OUTBOUND: 1
}
export const tabScreens = {
  LOGIN: 0,
  REGISTER: 1
}

export const orderStatus = {
  DRAFT: 0,
  PENDING: 1,
  PENDING_KYC: 2,
  PENDING_FIRST_PAYMENT: 3,
  ACTIVE: 4,
  ON_HOLD: 5,
  UNPAID: 6,
  PAID: 7,
  CLOSED: 8,
  PENDING_FOR_CLOSE: 9,
  PENDING_UPDATE_APPROVAL: 10
}

export const charCodes = {
  ENTER: 13,
  ARROW_LEFT: 37,
  BACKSPACE: 8,
  ZERO_ALPHANUMERIC: 48,
  NINE_ALPHANUMERIC: 57,
  ZERO_NUMERIC: 96,
  NINE_NUMERIC: 105
}

export const sepaPaymentDates = {
  SEPA_8TH: 8,
  SEPA_18TH: 18
}

export const settingsKey = {
  CROATIAN_LANGUAGE_STATUS: 'croatianLanguageStatus',
  REFERRAL_SETTINGS: 'referralSettings',
  AVAILABLE_LANGUAGES: 'availableLanguage'
}

export const loginLinkKeys = {
  BLOG: 'blog',
  MEET_ADVISOR: 'meetAdvisor',
  MARKET_PRICES: 'marketPrices',
  DATA_DELETITION_REQUEST: 'dataDeletitionRequest'
}

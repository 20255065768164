import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import sprintf from 'i18next-sprintf-postprocessor'
import config from 'config/config'
import { languageCodes } from '../../../constants/Languages'

const translationsFolderPath = `./instances/${config.INSTANCE_KEY}/languages`

const resources = Object.values(languageCodes).reduce((resourcesObj, code) => {
  resourcesObj[code] = { translation: require(`${translationsFolderPath}/${code}.json`) }
  return resourcesObj
}, {})

const currentLanguage = localStorage.getItem('currentLanguageCode')

i18n.use(initReactI18next).use(sprintf).init({
  resources,
  lng: currentLanguage,
  fallbackLng: languageCodes.EN,
  debug: true,
  overloadTranslationOptionHandler: sprintf.overloadTranslationOptionHandler,
  interpolation: {
    escapeValue: false
  },
  react: {
    wait: true
  }
})

export default i18n

import { createTheme } from '@mui/material'
import typography from './typography'

const MuiTheme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    }
  },
  palette: {
    primary: {
      main: '#2F80ED', // $primary
      contrastText: '#FFFFFF'
    },
    grey: {
      300: '#fefefe', // $inheritDefault1
      A100: '#f8f9ff' // $inheritDefault2
    },
    secondary: {
      main: '#2F80ED' // $secondary
    },
    error: {
      main: '#f83245' // $red
    },
    success: {
      main: '#1bc943' // $green
    },
    warning: {
      main: '#f4772e' // $orange
    },
    helpers: {
      primary: '#2F80ED', // $blue
      main: 'rgba(25, 46, 91, .035)'
    },
    contrastThreshold: 3,
    tonalOffset: 0.1
  },
  shape: {
    borderRadius: '0.5rem'
  },
  overrides: {
    MuiButton: {
      text: {
        paddingLeft: '14px',
        paddingRight: '14px'
      },
      containedSizeSmall: {
        paddingLeft: '14px',
        paddingRight: '14px'
      },
      root: {
        textTransform: 'none',
        fontWeight: 'normal'
      }
    },
    MuiTooltip: {
      tooltip: {
        backgroundColor: '#070919', // $second
        padding: '8px 16px',
        fontSize: '13px'
      },
      arrow: {
        color: '#070919' // $second
      }
    }
  },
  typography
})

export default MuiTheme

import { useLocation, useParams } from 'react-router-dom'
import { useNavigate } from 'react-router'

const useRouter = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const params = useParams()
  
  return { location, navigate, params }
}

export default useRouter

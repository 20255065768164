import React, { Fragment } from 'react'
import clsx from 'clsx'
import { Facebook, Instagram, LinkedIn } from '@mui/icons-material'
import { Logo } from '../../layout-components'
import { getCurrentLanguage, localize } from '../../services'

import { Container, Toolbar } from '@mui/material'
import { IconStyled, MenuButton, ToolBarTitle, TopBar, TopBarDivider, TopBarText } from './styles'

const PublicHeader = props => {
  const { externalLinks } = props
  const links = externalLinks ? externalLinks[getCurrentLanguage()] : {}
  
  return (
    <Fragment>
      <div>
        <TopBar position="absolute" color="secondary" className="w-full">
          <Toolbar sx={{ justifyContent: 'space-between', minHeight: '60px !important' }}>
            <Container>
              <div
                className={clsx(
                  'd-flex flex-column flex-md-row justify-content-between'
                )}
              >
                <div
                  className={clsx(
                    'd-flex w-full col-md-6 justify-content-center justify-content-md-start header-logo-wrapper'
                  )}
                >
                  <div className={clsx('d-flex d-flex-row')}>
                    <div className={clsx('d-flex d-flex-row')}>
                      <TopBarText
                        className="font-size-16 text-nowrap text-gold">
                        <span>{localize('questions')}</span>
                        <a
                          className="text-white"
                          href={`tel:${(links?.phoneNumber ||
                            localize('externalLinks.phoneNumber')).replace(
                            /\s/g,
                            ''
                          )}`}
                        >
                          {links?.phoneNumber || localize('phoneNumberText')}
                        </a>
                      </TopBarText>
                    </div>
                    <TopBarDivider className="mx-3 mt-1 header-logo-wrapper" orientation="vertical"/>
                    <div className={clsx('d-flex d-flex-row')}>
                      <TopBarText
                        className="font-size-16 text-nowrap text-gold">
                        <span>{localize('approvalScreen.email') + ': '}</span>
                        <a
                          className="text-white"
                          href={`mailto:${links?.email ||
                          localize('externalLinks.email')}`}
                        >
                          {links?.email || localize('emailText')}
                        </a>
                      </TopBarText>
                    </div>
                  </div>
                </div>
                <div className={clsx('w-full col-md-6')}>
                  <div
                    className={clsx(
                      'd-flex w-full justify-content-center justify-content-md-end'
                    )}
                  >
                    <IconStyled
                      href={links?.facebook ||
                        localize('externalLinks.facebook')}
                      target="_blank"
                    >
                      <Facebook fontSize="small"/>
                    </IconStyled>
                    <IconStyled
                      href={links?.linkedin ||
                        localize('externalLinks.linkedin')}
                      target="_blank"
                    >
                      <LinkedIn fontSize="small"/>
                    </IconStyled>
                    <IconStyled
                      href={links?.instagram ||
                        localize('externalLinks.instagram')}
                      target="_blank"
                    >
                      <Instagram fontSize="small"/>
                    </IconStyled>
                  </div>
                </div>
              </div>
            </Container>
          </Toolbar>
          <ToolBarTitle className="toolBarTitle">
            <Container
              className={clsx('d-flex d-flex-row justify-content-between header-nav-wrapper-min-height')}
            >
              <div className={clsx('d-flex d-flex-col')}>
                <div className={clsx('d-flex d-flex-row mb-2')}>
                  <Logo externalLinks={links}/>
                </div>
              </div>
              <div className={clsx('d-flex d-flex-col')}>
                <div className={clsx('d-flex d-flex-row dark-text')}>
                  <MenuButton
                    className="font-size-16"
                    href={links?.contact || localize('externalLinks.contact')}
                    target="_blank"
                  >
                    {localize('contact')}
                  </MenuButton>
                  <MenuButton
                    className="font-size-16"
                    href={links?.faq || localize('externalLinks.faq')}
                    target="_blank"
                  >
                    {localize('faq')}
                  </MenuButton>
                </div>
              </div>
            </Container>
          </ToolBarTitle>
        </TopBar>
      </div>
    </Fragment>
  )
}

export default PublicHeader

import React, { Fragment, useEffect, useState } from 'react'
import CssBaseline from '@mui/material/CssBaseline'
import Container from '@mui/material/Container'
import clsx from 'clsx'
import { Outlet } from 'react-router-dom'
import { PublicFooter, PublicHeader } from '../../layout-components'
import { ServerManager } from '../../services'
import { styled } from '@mui/material/styles'

const ContentWrapper = styled('div')({
  paddingTop: 150,
  '&.sm\\:d-w-full': {
    width: '100%'
  }
})

const PublicLayout = () => {
  const [externalLinks, setExternalLinks] = useState({})
  
  useEffect(() => {
    const fetchExternalLinks = async () => {
      const response = await ServerManager.getExternalLinks()
      if (response.success) {
        setExternalLinks(response.data.data)
      }
    }
    
    fetchExternalLinks()
  }, [])
  
  return (
    <Fragment>
      <CssBaseline/>
      <Container maxWidth="lg">
        <PublicHeader externalLinks={externalLinks}/>
        <ContentWrapper className={clsx('app-wrapper sm:d-w-full')}>
          <Outlet/>
        </ContentWrapper>
      </Container>
      <PublicFooter externalLinks={externalLinks}/>
    </Fragment>
  )
}

export default PublicLayout

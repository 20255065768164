import { HIDE_LOADER, SHOW_LOADER } from './types'

class LoaderManager {
  static showLoader (decoded) {
    return {
      type: SHOW_LOADER,
      payload: decoded
    }
  }
  
  static hideLoader () {
    return { type: HIDE_LOADER }
  }
}

export default LoaderManager

import React from 'react'

export function ErrorSvg () {
  return <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
              xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd"
          d="M0.666504 8.00008C0.666504 12.0667 3.93317 15.3334 7.99984 15.3334C12.0665 15.3334 15.3332 12.0667 15.3332 8.00008C15.3332 3.93341 12.0665 0.666748 7.99984 0.666748C3.93317 0.666748 0.666504 3.93341 0.666504 8.00008ZM1.99984 8.00008C1.99984 4.66675 4.6665 2.00008 7.99984 2.00008C11.3332 2.00008 13.9998 4.66675 13.9998 8.00008C13.9998 11.3334 11.3332 14.0001 7.99984 14.0001C4.6665 14.0001 1.99984 11.3334 1.99984 8.00008ZM8.6665 8.00008V5.33341C8.6665 4.93341 8.39984 4.66675 7.99984 4.66675C7.59984 4.66675 7.33317 4.93341 7.33317 5.33341V8.00008C7.33317 8.40008 7.59984 8.66675 7.99984 8.66675C8.39984 8.66675 8.6665 8.40008 8.6665 8.00008ZM8.59965 10.9334C8.59965 11 8.53299 11.0667 8.46632 11.1334C8.33299 11.2667 8.19965 11.3334 7.93299 11.3334C7.73299 11.3334 7.59965 11.2667 7.46632 11.1334C7.33299 11 7.26632 10.8667 7.26632 10.6667C7.26632 10.5886 7.2892 10.5334 7.30815 10.4876C7.32155 10.4553 7.33299 10.4276 7.33299 10.4C7.33299 10.3334 7.39965 10.2667 7.46632 10.2C7.66632 10 7.93299 9.93336 8.19965 10.0667C8.23299 10.0667 8.24965 10.0834 8.26632 10.1C8.28299 10.1167 8.29965 10.1334 8.33299 10.1334C8.39965 10.1334 8.46632 10.2 8.46632 10.2C8.49965 10.2334 8.51632 10.2667 8.53299 10.3C8.54965 10.3334 8.56632 10.3667 8.59965 10.4C8.66632 10.4667 8.66632 10.6 8.66632 10.6667C8.66632 10.7 8.64965 10.75 8.63299 10.8C8.61632 10.85 8.59965 10.9 8.59965 10.9334Z"
          fill="black"/>
    <mask id="mask0" maskUnits="userSpaceOnUse" x="0" y="0" width="16"
          height="16">
      <path fillRule="evenodd" clipRule="evenodd"
            d="M0.666504 8.00008C0.666504 12.0667 3.93317 15.3334 7.99984 15.3334C12.0665 15.3334 15.3332 12.0667 15.3332 8.00008C15.3332 3.93341 12.0665 0.666748 7.99984 0.666748C3.93317 0.666748 0.666504 3.93341 0.666504 8.00008ZM1.99984 8.00008C1.99984 4.66675 4.6665 2.00008 7.99984 2.00008C11.3332 2.00008 13.9998 4.66675 13.9998 8.00008C13.9998 11.3334 11.3332 14.0001 7.99984 14.0001C4.6665 14.0001 1.99984 11.3334 1.99984 8.00008ZM8.6665 8.00008V5.33341C8.6665 4.93341 8.39984 4.66675 7.99984 4.66675C7.59984 4.66675 7.33317 4.93341 7.33317 5.33341V8.00008C7.33317 8.40008 7.59984 8.66675 7.99984 8.66675C8.39984 8.66675 8.6665 8.40008 8.6665 8.00008ZM8.59965 10.9334C8.59965 11 8.53299 11.0667 8.46632 11.1334C8.33299 11.2667 8.19965 11.3334 7.93299 11.3334C7.73299 11.3334 7.59965 11.2667 7.46632 11.1334C7.33299 11 7.26632 10.8667 7.26632 10.6667C7.26632 10.5886 7.2892 10.5334 7.30815 10.4876C7.32155 10.4553 7.33299 10.4276 7.33299 10.4C7.33299 10.3334 7.39965 10.2667 7.46632 10.2C7.66632 10 7.93299 9.93336 8.19965 10.0667C8.23299 10.0667 8.24965 10.0834 8.26632 10.1C8.28299 10.1167 8.29965 10.1334 8.33299 10.1334C8.39965 10.1334 8.46632 10.2 8.46632 10.2C8.49965 10.2334 8.51632 10.2667 8.53299 10.3C8.54965 10.3334 8.56632 10.3667 8.59965 10.4C8.66632 10.4667 8.66632 10.6 8.66632 10.6667C8.66632 10.7 8.64965 10.75 8.63299 10.8C8.61632 10.85 8.59965 10.9 8.59965 10.9334Z"
            fill="white"/>
    </mask>
    <g mask="url(#mask0)">
      <rect width="16" height="16" fill="#EB5757"/>
    </g>
  </svg>
}

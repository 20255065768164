import { AxiosInstance } from 'services'
import LoaderManager from '../../store/actions/LoaderManager'
import store from 'config/configureStore'

export async function postRequest (apiLocation, body) {
  return AxiosInstance.post(apiLocation, body).then(res => {
    return { success: true, data: res.data }
  }).catch(err => {
    return { success: false, err }
  })
}

export async function getRequest (
  apiLocation, withLoader = true, loaderDetails = {}) {
  if (withLoader) {
    store().dispatch(LoaderManager.showLoader(loaderDetails))
  }
  return AxiosInstance.get(apiLocation).then(res => {
    return { success: true, data: res.data }
  }).catch(err => {
    return { success: false, err }
  }).finally(() => {
    //   store().dispatch(LoaderManager.hideLoader())
  })
}

export async function putRequest (apiLocation, body) {
  return AxiosInstance.put(apiLocation, body).then(res => {
    return { success: true, data: res.data }
  }).catch(err => {
    return { success: false, err }
  })
}

export async function deleteRequest (apiLocation) {
  return AxiosInstance.delete(apiLocation).then(res => {
    return { success: true, data: res.data }
  }).catch(err => {
    return { success: false, err }
  })
}

export async function optionsRequest (apiLocation) {
  return AxiosInstance.options(apiLocation).then(res => {
    return { success: true, data: res.data }
  }).catch(err => {
    return { success: false, err: err.response }
  })
}

import React from 'react'
import moment from 'moment'
import { Box, Typography } from '@mui/material'

const NUMBER_PRECISION = 4

class GlobalManager {
  static isEmpty (value) {
    return (
      value === undefined ||
      value === null ||
      (typeof value === 'object' && Object.keys(value).length === 0) ||
      (typeof value === 'string' && value.trim().length === 0)
    )
  }
  
  static ifExistPropertySet (data, property, defaultValue = '') {
    if (this.isEmpty(data) || this.isEmpty(data[property])) {
      return defaultValue
    } else {
      return data[property]
    }
  }
  
  static ifExistSet (value) {
    if (this.isEmpty(value)) {
      return ''
    } else {
      return value
    }
  }
  
  static customBodyRenderType (type, value) {
    if (type === 'text') {
      return value.charAt(0).toUpperCase() + value.slice(1)
    }
    if (type === 'value') {
      return !this.isEmpty(value) ? value : 0
    }
    if (type === 'date') {
      return moment(value).format('DD-MM-YYYY')
    } else { return value }
  }
  
  static addOnCurrent (object, data) {
    return { ...object, data }
  }
  
  static ifExistSetWithDefaultValue (value1, value2) {
    return !this.isEmpty(value1) ? value1 : value2
  }
  
  static TabPanel (props) {
    const { children, value, index, ...other } = props
    return (
      <Typography
        component="div"
        role="tabpanel"
        hidden={value !== index}
        {...other}>
        {value === index && <Box p={4}>{children}</Box>}
      </Typography>
    )
  }
  
  static getLocaleFormatDate (inputDate) {
    if (!this.isEmpty(inputDate)) {
      return moment(inputDate).local().format('DD.MM.YYYY')
    }
  }
  
  static getLocaleFormatTime (inputTime) {
    return moment(inputTime).local().format(moment.HTML5_FMT.TIME)
  }
  
  static toFixedNoRounding (n, p = NUMBER_PRECISION) {
    const reg = new RegExp('^-?\\d+(?:\\.\\d{0,' + p + '})?', 'g')
    const a = n.toString().match(reg)[0]
    const dot = a.indexOf('.')
    if (dot === -1) { // integer, insert decimal dot and pad up zeros
      return a + '.' + '0'.repeat(p)
    }
    const b = p - (a.length - dot) + 1
    return b > 0 ? (a + '0'.repeat(b)) : a
  }
  
  static camelToSnakeCase (str) {
    return str.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`)
  }
  
  static isDevelopment () {
    return !process.env.NODE_ENV || process.env.NODE_ENV === 'development'
  }
  
  static isProduction () {
    return process.env.NODE_ENV === 'production'
  }
}

export default GlobalManager

import { styled } from '@mui/material/styles'
import { AppBar, ButtonBase, Divider } from '@mui/material'

export const BottomBar = styled(AppBar)({
  top: 'auto',
  bottom: 0,
  fontFamily: '\'DM Sans\', serif',
  minHeight: 296,
  backgroundColor: 'transparent'
})

export const BottomBarDivider = styled(Divider)({
  backgroundColor: 'rgba(0,0,0,0.1)'
})

export const MenuButton = styled(ButtonBase)({
  minWidth: '60px',
  padding: 16,
  fontWeight: 'bold',
  whiteSpace: 'nowrap'
})

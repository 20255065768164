import React from 'react'
import { Helmet } from 'react-helmet'
import { localize } from 'services'
import metaImage from '../../assets/images/meta_image.jpg'

class MetaTag extends React.Component {
  render () {
    return (
      <div className="wrapper">
        <Helmet>
          <meta name="description" content={localize('descriptionMetaTag')}/>
          <meta property="og:description"
                content={localize('descriptionMetaTag')}/>
          <meta property="og:url"
                content={process.env.REACT_APP_BASE_URL + '/users/login'}/>
          <meta property="og:type" content="website"/>
          <meta property="og:title" content={localize('titleMetaTag')}/>
          <meta property="og:image" content={metaImage}/>
        </Helmet>
      </div>
    )
  }
}

export default MetaTag

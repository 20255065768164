import { GlobalManager } from 'services'

const NUMBER_PRECISION = 4
const MULTIPLIER = 100000
export default class CalculationManager {
  static notZero (n) {
    n = +n
    if (!n) {
      throw new Error('Can\'t divide with zero')
    }
    return n
  }
  
  static calculatePrice (shareP, amountP, precision = NUMBER_PRECISION) {
    const share = parseFloat(shareP)
    const amount = parseFloat(amountP)
    return GlobalManager.toFixedNoRounding(parseFloat(amount * share / 100),
      precision)
  }
  
  static calculatePriceWithAddon (priceP, addonP) {
    const price = parseFloat(priceP)
    const addon = parseFloat(addonP)
    return GlobalManager.toFixedNoRounding(
      this.notZero(price + price * (addon / 100)))
  }
  
  static calculateQuantity (amountP, priceP, addonP) {
    const amount = parseFloat(amountP)
    return GlobalManager.toFixedNoRounding(
      (amount / this.notZero(this.calculatePriceWithAddon(priceP, addonP))))
  }
  
  static calculateSellAmount (quantity, price, reductionPricePercentage) {
    const currentQuantity = parseFloat(quantity)
    const currentPrice = parseFloat(price)
    const currentReductionPricePercentage = parseFloat(reductionPricePercentage)
    return GlobalManager.toFixedNoRounding(currentQuantity *
      (currentPrice - currentPrice * (currentReductionPricePercentage / 100)))
  }
  
  static calculateSellQuantity (amount, price, reductionPricePercentage) {
    const currentAmount = parseFloat(amount)
    return GlobalManager.toFixedNoRounding(currentAmount / this.notZero(
      this.calculatePriceWithReductionPercentage(price,
        reductionPricePercentage)))
  }
  
  static calculatePriceWithReductionPercentage (
    price, reductionPricePercentage) {
    const currentPrice = parseFloat(price)
    const currentReductionPricePercentage = parseFloat(reductionPricePercentage)
    return GlobalManager.toFixedNoRounding(
      currentPrice - currentPrice * currentReductionPricePercentage / 100)
  }
  
  static calculateWithdrawAmount (quantity, price) {
    const currentQuantity = parseFloat(quantity)
    const currentPrice = parseFloat(price)
    return GlobalManager.toFixedNoRounding(currentQuantity * currentPrice)
  }
  
  static calculateAmountPerProduct (totalAmountP, shareP) {
    const totalAmount = parseFloat(totalAmountP)
    const share = parseFloat(shareP)
    return GlobalManager.toFixedNoRounding((totalAmount * (share / 100)))
  }
  
  static calculateNewAmount (currentAmountP, orderAmountP) {
    const currentAmount = parseFloat(currentAmountP)
    const orderAmount = parseFloat(orderAmountP)
    return GlobalManager.toFixedNoRounding(currentAmount + orderAmount)
  }
  
  static getRestOfPercentage (totalP, precision = NUMBER_PRECISION) {
    const total = parseFloat(totalP)
    return GlobalManager.toFixedNoRounding(100 - total, precision)
  }
  
  static calculateTotalPrice (priceP, quantityP, precision = NUMBER_PRECISION) {
    const price = parseFloat(priceP)
    const quantity = parseFloat(quantityP)
    return GlobalManager.toFixedNoRounding(parseFloat(quantity * price),
      precision)
  }
  
  static calculateAmountOfCommission (price, commission) {
    return price * commission / 100
  }
  
  static addition (value1, value2) {
    const val1 = parseFloat(value1) * MULTIPLIER
    const val2 = parseFloat(value2) * MULTIPLIER
    return GlobalManager.toFixedNoRounding((val1 + val2) / MULTIPLIER)
  }
  
  static subtraction (value1, value2) {
    const val1 = parseFloat(value1) * MULTIPLIER
    const val2 = parseFloat(value2) * MULTIPLIER
    return GlobalManager.toFixedNoRounding((val1 - val2) / MULTIPLIER)
  }
}

import React from 'react'

export function GlobalConfirmIconSvg () {
  return <svg width="246" height="220" viewBox="0 0 246 220" fill="none"
              xmlns="http://www.w3.org/2000/svg">
    <path opacity="0.05"
          d="M3.14579 54.228C-0.185842 63.6126 -0.887106 73.7331 1.11823 83.4892C3.94066 96.4979 13.6607 107.938 12.004 121.808C11.0091 130.138 11.1868 137.765 10.986 145.948C10.5751 162.744 22.2842 176.65 36.0201 183.61C47.1774 189.262 59.8614 190.754 72.1084 189.673C83.1288 188.701 93.9815 185.8 104.457 182.206C114.553 178.742 124.352 174.437 135.236 174.76C144.289 175.027 152.683 178.4 161.275 180.742C181.786 186.334 206.053 185.539 223.487 173.422C242.597 160.139 250.316 134.936 243.638 112.91C240.056 101.096 235.274 90.857 237.004 78.2694C237.855 72.077 239.124 65.7751 237.952 59.6368C236.874 53.9866 233.783 48.9025 230.118 44.4763C220.235 32.5412 206.166 24.9305 191.541 20.4534C174.949 15.3739 156.53 12.6044 139.277 15.2343C105.763 20.3431 98.4698 15.3747 63.4364 10.3893C39.9675 7.04983 17.9382 25.7527 7.24325 45.0478C5.61594 47.9837 4.24502 51.0552 3.14579 54.228Z"
          fill="#2F80ED"/>
    <path opacity="0.05"
          d="M30.9987 81.5251C35.84 75.1189 43.3247 70.7669 46.9914 63.4057C51.3813 54.5925 50.4567 44.1919 54.3773 35.1876C60.171 21.8838 70.6618 10.5831 83.8036 4.37742C90.136 1.38681 97.0858 -0.432793 103.87 0.0888621C114.321 0.892242 123.336 7.13198 130.386 14.5837C140.878 25.6732 148.296 41.4234 160.888 50.3086C167.834 55.2125 176.003 50.0098 183.365 48.3597C191.616 46.5646 200.182 46.8542 208.293 49.2026C222.942 53.382 235.556 63.908 241.865 77.7822C248.601 92.5959 247.643 113.075 235.001 125.813C229.153 131.705 221.221 135.643 216.881 142.701C211.434 151.558 210.795 163.53 209.274 173.378C207.519 184.444 202.944 194.867 195.993 203.64L195.755 203.939C189.911 211.246 181.844 216.434 172.784 218.712C163.724 220.99 154.17 220.232 145.58 216.555L108.527 200.456C70.9303 184.121 42.3885 157.797 28.6857 119.654C25.7205 111.4 24.6431 93.8033 28.421 85.7432C29.1307 84.2506 29.9947 82.8369 30.9987 81.5251Z"
          fill="#2F80ED"/>
    <path
      d="M128.766 57.3477C116.018 57.3477 105.644 67.7211 105.644 80.4691C105.644 93.2171 116.013 103.596 128.766 103.596C141.519 103.596 151.887 93.2224 151.887 80.4744C151.887 67.7264 141.514 57.3477 128.766 57.3477ZM139.726 75.928L128.163 90.1595C127.688 90.7464 126.984 91.104 126.231 91.1413C126.189 91.1413 126.14 91.1467 126.092 91.1467C125.388 91.1467 124.71 90.8692 124.203 90.3676L117.09 83.2545C116.05 82.214 116.05 80.5224 117.09 79.4819C118.131 78.4414 119.823 78.4414 120.863 79.4819L125.884 84.5032L135.58 72.5663C136.503 71.4243 138.189 71.2483 139.331 72.1767C140.484 73.1052 140.66 74.7861 139.726 75.928Z"
      fill="#C1921A"/>
    <path
      d="M177.758 69.0664H157.75V78.5228H176.586V135.275H82.4143V78.5228H101.25V69.0664H81.2419C76.6937 69.0664 73 72.7766 73 77.3381V136.453C73 141.014 76.6937 144.732 81.2419 144.732H120.086V154.188H110.671C108.072 154.188 105.961 156.309 105.961 158.92C105.961 161.53 108.065 163.652 110.664 163.652H148.329C150.928 163.652 153.039 161.53 153.039 158.92C153.039 156.309 150.935 154.188 148.329 154.188H138.914V144.732H177.758C182.299 144.732 186 141.022 186 136.453V77.3381C186 72.7766 182.306 69.0664 177.758 69.0664Z"
      fill="#2F80ED"/>
  </svg>
}

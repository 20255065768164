import React, { useEffect } from 'react'
import ReactGA from 'react-ga'
import { Provider, useDispatch, useSelector } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import configureStore from './config/configureStore'
import RoutesComponent from './Routes'
import ScrollToTop from './utils/ScrollToTop'
import './assets/base.css'
import CssBaseline from '@mui/material/CssBaseline'
import Cookies from 'js-cookie'
import { LinearProgress } from '@mui/material'
import { createBrowserHistory } from 'history'
import './assets/fonts/fonts.css'
import MetaTag from './components/MetaTag/MetaTag'
import TagManager from 'react-gtm-module'

import config from 'config/config'
import AuthenticationManager from './store/actions/AuthenticationManager'
import { optionsRequest } from './services/RequestManager/RequestManager'
import { GOOGLE_ANALYTICS_USER_ID } from 'constants/Constants'
import { GlobalManager, initializeLanguage } from 'services'
import 'assets/icons.js'

const store = configureStore()
const history = createBrowserHistory()

const App = () => {
  const dispatch = useDispatch()
  const jwtToken = Cookies.get(config.TM_JWT_COOKIE_KEY)
  const app = useSelector(store => store.App)
  
  useEffect(() => {
    async function checkUserTokenValid () {
      if (jwtToken) {
        const response = await optionsRequest('/users/api/profile')
        if (response.success) {
          try {
            dispatch(AuthenticationManager.setAuthToken(jwtToken))
          } catch (error) {
            dispatch(AuthenticationManager.logoutUser)
          }
        } else {
          dispatch(AuthenticationManager.logoutUser)
        }
      }
    }
    
    checkUserTokenValid()
  }, [dispatch])
  
  useEffect(() => {
    initializeLanguage()
    initializeGoogleAnalytics()
  }, [])
  
  const initializeGoogleAnalytics = () => {
    if (!GlobalManager.isEmpty(config.GOOGLE_TAG_ID)) {
      TagManager.initialize({
        gtmId: config.GOOGLE_TAG_ID,
        events: {
          login: 'Log In',
          register: 'Sign Up'
        }
      })
    }
    
    if (!GlobalManager.isEmpty(config.TRACKING_ID)) {
      ReactGA.initialize(config.TRACKING_ID)
      ReactGA.set({ userId: GOOGLE_ANALYTICS_USER_ID })
      history.listen(location => {
        ReactGA.set({ page: location.pathname })
        ReactGA.pageview(location.pathname)
      })
    }
  }
  
  return (
    <BrowserRouter basename="/" future={{
      v7_startTransition: true,
      v7_relativeSplatPath: true
    }}>
      <MetaTag/>
      <CssBaseline/>
      <div className="main-loader">
        {app.loadingBar && <LinearProgress variant="query" color="primary"/>}
      </div>
      <ScrollToTop>
        <RoutesComponent/>
      </ScrollToTop>
    </BrowserRouter>
  )
}

const Root = () => {
  return <Provider store={store}><App/></Provider>
}
export default Root

import React, { Fragment, lazy, Suspense } from 'react'
import { Route } from 'react-router-dom'
import { ThemeProvider } from '@mui/material/styles'
import { ClimbingBoxLoader } from 'react-spinners'
import { Navigate, Routes } from 'react-router'

import MuiTheme from './theme'
import { MinimalLayout, PublicLayout } from './layout-blueprints'
import PagesError404 from 'containers/ErrorPageScreens/ErrorPage404'

const LoginScreen = lazy(
  () => import('./containers/AuthenticationScreens/LoginScreen'))
const IdentifyScreen = lazy(
  () => import('./containers/AuthenticationScreens/IdentifyScreen'))
const Verification2FaScreen = lazy(
  () => import('./containers/AuthenticationScreens/2FaVerificationScreen'))
const SetupPasswordScreen = lazy(
  () => import('./containers/AuthenticationScreens/SetupPasswordScreen'))
const RevisionConfirmationScreen = lazy(
  () => import('./containers/RevisionConfirmationScreen/RevisionConfirmationScreen'))
const WithdrawalProductsConfirmationScreen = lazy(
  () => import('./containers/WithdrawAssetsConfirmationScreen'))
const ErrorTokenScreen = lazy(
  () => import('./containers/ErrorTokenScreen/ErrorTokenScreen'))
const SellingAssetsConfirmationScreen = lazy(
  () => import('./containers/SellingAssetsConfirmationScreen'))
const ManageSubscriptionsScreen = lazy(
  () => import('./containers/ManageSubscriptionsScreen/ManageSubscriptionsScreen'))
const Forbidden = lazy(() => import('./containers/ErrorPageScreens/Forbidden'))
const OneTimeOrderConfirmationScreen = lazy(
  () => import('./containers/OneTimeOrderConfirmationScreen'))
const TransferAssetsConfirmationScreen = lazy(
  () => import('./containers/TransferAssetsConfirmationScreen'))
const SavingPlanConfirmationScreen = lazy(
  () => import('./containers/SavingPlanConfirmationScreen'))
const SavingPlanCloseConfirmationScreen = lazy(
  () => import('./containers/SavingPlanCloseConfirmationScreen'))
const DeactivationConfirmationScreen = lazy(
  () => import('./containers/DeactivationConfirmationScreen'))

const RoutesComponent = () => {
  const SuspenseLoading = () => (
    <Fragment>
      <div
        className="d-flex align-items-center flex-column vh-100 justify-content-center text-center py-3">
        <div className="d-flex align-items-center flex-column px-4">
          <ClimbingBoxLoader color="#ffdf00" loading={true}/>
        </div>
      </div>
    </Fragment>
  )
  
  return (
    <ThemeProvider theme={MuiTheme}>
      <Suspense fallback={<SuspenseLoading/>}>
        <Routes>
          <Route path="/" element={<Navigate to="/login" replace/>}/>
          <Route path="/users/login" element={<LoginScreen/>}/>
          
          <Route element={<PublicLayout/>}>
            <Route path="/users/public/saving-plan-approval/:id"
                   element={<SavingPlanConfirmationScreen/>}/>
            <Route path="/users/public/saving-plan-close-approval/:id"
                   element={<SavingPlanCloseConfirmationScreen/>}/>
            <Route path="/users/public/revision-confirmation/:id"
                   element={<RevisionConfirmationScreen/>}/>
            <Route path="/users/public/selling-assets/:id"
                   element={<SellingAssetsConfirmationScreen/>}/>
            <Route path="/users/public/withdrawal-products/:id"
                   element={<WithdrawalProductsConfirmationScreen/>}/>
            <Route path="/users/public/transfer-assets-confirmation/:id"
                   element={<TransferAssetsConfirmationScreen/>}/>
            <Route path="/users/public/manage-subscriptions/:id"
                   element={<ManageSubscriptionsScreen/>}/>
            <Route path="/users/error-token" element={<ErrorTokenScreen/>}/>
            <Route path="/users/public/one-time-order-confirmation/:id"
                   element={<OneTimeOrderConfirmationScreen/>}/>
            <Route path="/users/public/deactivation-confirmation/:id"
                   element={<DeactivationConfirmationScreen/>}/>
          </Route>
          
          <Route element={<MinimalLayout/>}>
            <Route path="/users/login" element={<LoginScreen/>}/>
            <Route path="/users/verification"
                   element={<Verification2FaScreen/>}/>
            <Route path="/users/identify" element={<IdentifyScreen/>}/>
            <Route path="/users/public/setup-password/:token"
                   element={<SetupPasswordScreen/>}/>
            <Route path="/users/public/forbidden" element={<Forbidden/>}/>
          </Route>
          
          <Route path="*" element={<PagesError404/>}/>
        </Routes>
      </Suspense>
    </ThemeProvider>
  )
}

export default RoutesComponent
